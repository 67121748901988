<template>
  <b-card>
    <b-tabs v-model="tabIndex">
      <b-tab title="Grupos" @click="clickTab">
        <ListGroupTypeBenefits @set-tab-index="setTabIndex" />
      </b-tab>
      <b-tab title="Crear" @click="clickTab">
        <GroupTypeBenefitCreate @set-tab-index="setTabIndex" />
      </b-tab>

      <b-tab>
        <template #title>
          <div :class="selectedGroupTypeBenefit ? '' : 'd-none'">Actualizar</div>
        </template>
        <GroupTypeBenefitEdit @set-tab-index="setTabIndex" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ListGroupTypeBenefits from "@/modules/fivesClub/components/catalogs/groupTypeBenefits/ListGroupTypeBenefits";
import GroupTypeBenefitCreate from "@/modules/fivesClub/components/catalogs/groupTypeBenefits/GroupTypeBenefitCreate";
import GroupTypeBenefitEdit from "@/modules/fivesClub/components/catalogs/groupTypeBenefits/GroupTypeBenefitEdit";

export default {
  components: {
    ListGroupTypeBenefits,
    GroupTypeBenefitCreate,
    GroupTypeBenefitEdit,
  },
  created() {},
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["selectedGroupTypeBenefit"]),
  },
  methods: {
    ...mapMutations("fivesClubCatalogs", ["setSelectedGroupTypeBenefit"]),
    setTabIndex(index) {
      this.tabIndex = index;
    },
    clickTab() {
      this.setSelectedGroupTypeBenefit(null);
    },
  },
};
</script>
